import React from 'react';
import { Link } from 'gatsby';
import { Button, Layout as AntLayout, Icon, Row, Col, Divider } from 'antd';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';

import NYC from '../components/images/nyc';
import DeskHands from '../components/images/deskHands';
import DeskHandsAlt from '../components/images/deskHandsAlt';
import Office from '../components/images/office';

const { Content } = AntLayout;

const IndexPage = () => (
  <Layout className="home-layout">
    <SEO title="IRC | Insurance Regulatory Consultants, LLC" />
    <Header>
      <h1>
        A nimble consulting firm that provides compliance solutions for
        insurance companies swiftly and efficiently.
      </h1>
      <p>
        <Link to="/about" className="plain-link">
          Read more about us <Icon type="caret-right" theme="filled" />
        </Link>
      </p>
    </Header>
    <Content className="home-content ant-layout-content">
      <Row gutter={20}>
        <Col sm={24} md={12}>
          <NYC />
        </Col>
        <Col xs={0} md={4}>
          <Row>
            <Col>
              <Office />
            </Col>
          </Row>
          <Row style={{ marginTop: '26px' }}>
            <Col>
              <DeskHandsAlt />
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={8}>
          <DeskHands />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={6} className="title-text">
          Who We Are
        </Col>
        <Col xs={24} md={18}>
          <p>
            Insurance Regulatory Consultants, LLC (IRC) is a full-service state
            filing and regulatory compliance company formed in 1997 in Lower
            Manhattan by John Battles and Kevin Purcell. Our staff executes
            forward-thinking strategies while applying more than 100 years of
            experience to provide compliance solutions to insurance companies,
            faster and more efficiently than our competitors. IRC has produced
            more than 75,000 approvals since the introduction of SERFF.
          </p>
          <p>
            <Button type="ghost">
              <Link to="/about">Meet our team</Link>
            </Button>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ push: 6, span: 18 }}>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={6} className="title-text">
          What We Do
        </Col>
        <Col xs={24} md={18} className="service-list">
          <Row>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" /> Product Development
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" />
              Rating Bureau Monitoring
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" />
              Filing Preparation &amp; Submission
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" />
              Statistical Reporting
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" />
              Filing Follow-Up, Status Updates, &amp; Storage
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="check-circle" theme="filled" />
              Actuarial Services
            </Col>
            <Col lg={24} xl={12}>
              <Icon type="right-circle" theme="filled" />{' '}
              <Link to="/services" className="plain-link">
                See all of our services
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
    <Footer />
  </Layout>
);

export default IndexPage;
